import React from "react"
import { Container, Row, Col, Carousel, CarouselIndicators, CarouselItem } from 'reactstrap'
import Layout from "../components/layout"
import FreeConsultationBanner from '../images/banner.jpg'
import bgImg1 from '../images/banner-image-1.png'
import bgImg2 from '../images/banner-image-2.png'
import bgImg3 from '../images/banner-image-3.png'
import about from '../images/about-solutions.png'
import whatWeDo from '../images/what-we-do.png'
import retsolution from '../images/retritment-solution.png'
import personalSolution from '../images/personal-solution.png'
import logoBlack from '../images/logo-black.png'
import logoBlack2 from '../images/logo-black2.png'
import { TeamDisplay, teams } from "../data/jsonData"
import { Testimonials } from "../components/testimonials"
import { seoData } from "../data/seo-data"

const items = [
  {
    id: 3,
    image: bgImg1,
    heading: "Solutions Consultants",
    subHeading: "personal financial advisers",
    text: "Solutions Consultants are your personal financial advisers, and above everything else, we provide clarity. "
  },{
    id: 1,
    image: bgImg3,
    heading: "Solutions Consultants",
    subHeading: "personal financial advisers",
    text: "Solutions Consultants are your personal financial advisers, and above everything else, we provide clarity. "
  }, {
    id: 2,
    image: bgImg2,
    heading: "Solutions Consultants",
    subHeading: "personal financial advisers",
    text: "Solutions Consultants are your personal financial advisers, and above everything else, we provide clarity. "
  },
]




const IndexPage = () => {

  return (
    <Layout footerType="HOMEPAGE" seo={{ title: seoData['index'].title, description: seoData.index.description, keyword: '' }} banner={<FreeConsulTationBanner />}>

      <section style={{ marginTop: 50, marginBottom: 50, background: 'none' }}>
        <Container>
          <Row className="justify-content-md-center" >
            <Col style={{ textAlign: "center" }} md={10}>
              <h3 className="header-font" >About Solutions Consultants</h3>
            </Col>
            <Col md={4}>
              <img src={about} alt="solutions consultant"/>
            </Col>
            <Col md={8}>
              <h4>How we help</h4>
              <ul>
                <li> Provide a tailored personal financial solution that suits your needs</li>
                <li> Educate and empower you  with the knowledge you need</li>
                <li> Assist you with making decisions that work towards your happiness, not just financial goals</li>
                <li> Take the stress out of managing and growing your assets</li>
                <li> We are in for the long haul. We help you navigate your life stages and changes as they happen</li>
                <li> Assist you in understanding your finances, your goals, and all the ‘white noise’ that comes along with financial products and decisions</li>
                <li> We are just a call away. We are real people and we want to help you navigate any changes</li>
              </ul>


            </Col>
          </Row>
        </Container>
      </section>

      <section style={{
        paddingTop: 0, paddingBottom: 50, // backgroundImage: `url(${mainBg2})`,
      }}>
        <Container>
          <Row style={{ paddingTop: 100 }}>
            <Col md={5}  >
              <h3 className="header-font" style={{ fontSize: '2.5em' }}>What We Do</h3>
              <p  >Our approach with each person or couple we initially speak to is the same: We listen to their story.</p>
              <p >Once we understand their life, background and current financial position, we then focus on their goals and objectives - the reason they are talking to us and WHY they feel they need change. </p>

            </Col>
            <Col md={6}>
              <img src={whatWeDo} alt="" style={{ marginTop: '0px' }} />
            </Col>
          </Row>
          <Row style={{ paddingTop: 100 }}>
            <Col md={1}>
            </Col>
            <Col md={5} >
              <h3 className="header-font" style={{ marginBottom: 0, fontSize: '2.5em' }}>Personal</h3><h3 className="sub-header" style={{ marginTop: -20, marginLeft: 30 }}> <img src={logoBlack} height="40px" alt="" /> </h3>
              <p style={{ color: '#000' }}>We help our clients recognise what lifestyle and financial goals they would like to achieve in the short and medium term.   </p>
              <p style={{ color: '#000' }}>
              Then we build a flexible plan so that they can take control of their lives, with advice on <a href="/financial-planning/">financial planning</a>, <a href="/insurance">insurance</a>, and <a href="/smsf">managing their superannuation funds</a>.  
              </p>
            </Col>
            <Col md={6}>
              <img src={personalSolution} alt="" className="marginminus50"/>
            </Col>
          </Row>
          <Row style={{ marginTop: 100 }}>
            <Col md={6}>
              <img style={{ marginTop: '-20px', }} src={retsolution} alt="" />
            </Col>
            <Col md={6}>
              <h3 className="header-font" style={{ marginBottom: 0, fontSize: '2.5em' }}>Retirement</h3><h3 className="sub-header" style={{ marginTop: -20, marginLeft: 30 }}> <img src={logoBlack} height="40px" alt="" /> </h3>
              <p  >Thinking about or transitioning into retirement can be extremely intimidating, and for most of us there are more questions than answers.</p>
              <p   > We help our clients work through these questions and achieve the lifestyle they wish for while managing their financial affairs. </p>

            </Col>
          </Row>
        </Container>
      </section>

      <Testimonials colButton={true} />

      <section style={{ marginTop: 75 }}>
        <Container style={{ textAlign: 'center' }}>
          <Row className="justify-content-md-center" >
            <Col md={12}>
              <h3 style={{ marginBottom: 15, fontSize: '2.5em' }} className="header-font">Meet The Team</h3>
              <h4 style={{ marginTop: 0, marginBottom: 30 }}>Meet the experienced financial experts who will help build and protect your wealth.</h4>
            </Col>
            <TeamDisplay {...teams.marc} />
            <TeamDisplay {...teams.james} />
            <TeamDisplay {...teams.megan} />
            <TeamDisplay {...teams.anne} />
            <TeamDisplay {...teams.josephine} />
          </Row>
        </Container>
      </section>
    </Layout>
  )

}


const FreeConsulTationBanner = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <div style={{ backgroundImage: `url(${FreeConsultationBanner})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <Container style={{ paddingTop: 110, paddingBottom: 50 }}>
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {items.map(item => {
            return (
              <CarouselItem
                onExiting={onExiting}
                onExited={onExited}
                key={item.id}
              >
                <Row>
                  <Col md={4} style={{ textAlign: 'left' }}>
                    <div style={{ marginTop: '29%' }}>
                      <img src={logoBlack2} alt="" width="80%" />
                      <h3 style={{ fontSize: '1.85em', color: '#000', lineHeight: 1, marginTop: 0 }}>Financial Consultants</h3>
                      <h3 className="sub-header" style={{ fontSize: '2.8em', textTransform: 'uppercase', fontWeight: 'bold', lineHeight: 1 }}>{item.subHeading}</h3>
                      <h4>{item.text}</h4>
                    </div>

                  </Col>
                  <Col md={8}>
                    <img src={item.image} alt={item.subHeading} />
                  </Col>
                </Row>
              </CarouselItem>
            );
          })}
          <a
            className="carousel-control-prev"
            data-slide="prev"
            href="#pablo"
            onClick={e => {
              e.preventDefault();
              previous();
            }}
            role="button"
          >
            <i className="now-ui-icons arrows-1_minimal-left"></i>
          </a>
          <a
            className="carousel-control-next"
            data-slide="next"
            href="#pablo"
            onClick={e => {
              e.preventDefault();
              next();
            }}
            role="button"
          >
            <i className="now-ui-icons arrows-1_minimal-right"></i>
          </a>
        </Carousel>
      </Container>
    </div>
  )
}

export default IndexPage

/*
 <Col md={4}>
              <img src={info} alt="" />
              <h4 className='sub-header' style={{ marginTop: 10, marginBottom: 10 }}>We’re Here for You</h4>
              <p>There’s a lot of noise in our industry, but we make it clear.</p>
              <p>We guide you from where you are now financially, to where you want to be in your future</p>
            </Col>
            <Col md={4}>
              <img src={bb} alt="" />
              <h4 className='sub-header' style={{ marginTop: 10, marginBottom: 10 }}>How Can We Help?</h4>
              <p style={{ textAlign: 'center' }}>
                - Prepare You For Your Future<br />
              -  We Work For You <br />
              -  Make Your Life Easier<br />
              -  Your Personal Coach to Achieve your Lifestyle Goals
              </p>
            </Col>
            <Col md={4}>
              <img src={cc} alt="" />
              <h4 className='sub-header' style={{ marginTop: 10, marginBottom: 10 }}>Get in Touch</h4>
              <p>What do you really want?  It’s a simple question to ask but it's very difficult to answer.</p>
              <p>Give us a call or <a href="mailto:info@solutionsconsultants.com.au">send an email</a>, let’s chat about how Solutions Consultants can work for you.</p>
            </Col>
*/